import { ContentLayout } from "@/components/Layout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import dayjs from "dayjs";
import { useState } from "react";
import {
  FaCcAmex,
  FaCcDiscover,
  FaCcMastercard,
  FaCcVisa,
  FaCreditCard,
} from "react-icons/fa";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { Button, InputLabel, Tag } from "../../../components/Elements";
import { Skeleton } from "../../../components/Elements/Skeleton";
import { cn } from "../../../utils/style";
import {
  useSubscription,
  useSubscriptionUpdate,
} from "../../auth/api/getSubscription";
import { useGetCreditCards } from "../../subscription/api/getCreditCards";
import { getInvoicePdf } from "../../subscription/api/getInvoicePdf";
import { useGetInvoices } from "../../subscription/api/getInvoices";
import { useGetUpcomingInvoice } from "../../subscription/api/getUpcomingInvoices";
import { EditBillingInformationDialog } from "../components/EditBillingInformationDialog";
import { UpdateCardDialog } from "../components/UpdateCardDialog";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY as string);

export const getBrandIcon = (brand: string) => {
  switch (brand && brand.toLowerCase()) {
    case "visa":
      return <FaCcVisa />;
    case "mastercard":
      return <FaCcMastercard />;
    case "amex":
      return <FaCcAmex />;
    case "discover":
      return <FaCcDiscover />;
    default:
      return <FaCreditCard />;
  }
};

export const Billing = () => {
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useSubscription({});
  const { data: invoices, isLoading: isLoadingInvoices } = useGetInvoices();
  const { data: billingInfo, isLoading: isLoadingSubscriptionUpdate } =
    useSubscriptionUpdate();
  const { data: creditCards, isLoading: isLoadingCreditCards } =
    useGetCreditCards();
  const [loadingInvoiceId, setLoadingInvoiceId] = useState<string | null>(null);

  const shouldFetchUpcomingInvoice = subscriptionData?.status === "active";

  const { data: upcomingInvoice, isLoading: isLoadingUpcomingInvoice } =
    useGetUpcomingInvoice({
      config: {
        enabled: shouldFetchUpcomingInvoice,
      },
    });

  const isLoadingBillingInfo =
    isLoadingSubscription ||
    isLoadingInvoices ||
    isLoadingUpcomingInvoice ||
    isLoadingSubscriptionUpdate ||
    isLoadingCreditCards;

  const billingDetails = {
    paymentMethod: {
      type: "Visa",
      lastFourDigits: "2493",
    },
    nextInvoice: upcomingInvoice ?? {
      amount: "$0.00",
      date: "N/A",
    },
    invoices: Array.isArray(invoices) ? invoices : [],
    address: {
      name: billingInfo?.billing_info?.name ?? "",
      address_line_1: billingInfo?.billing_info?.address_line_1 ?? "",
      address_line_2: billingInfo?.billing_info?.address_line_2 ?? "",
      city: billingInfo?.billing_info?.city ?? "",
      country: billingInfo?.billing_info?.country ?? "",
      email: billingInfo?.billing_info?.email ?? "",
      state: billingInfo?.billing_info?.state ?? "",
      zip_code: billingInfo?.billing_info?.zip_code ?? "",
    },
  };

  const creditCardDetails =
    creditCards && creditCards.data && creditCards.data.length > 0
      ? {
          brand: creditCards.data[0].brand,
          lastFourDigits: creditCards.data[0].last4,
          expMonth: creditCards.data[0].exp_month,
          expYear: creditCards.data[0].exp_year,
        }
      : {
          brand: "",
          lastFourDigits: "",
          expMonth: "",
          expYear: "",
        };

  const isBillingInfoComplete =
    billingInfo?.billing_info?.name &&
    billingInfo?.billing_info?.address_line_1 &&
    billingInfo?.billing_info?.city &&
    billingInfo?.billing_info?.country &&
    billingInfo?.billing_info?.email &&
    billingInfo?.billing_info?.state &&
    billingInfo?.billing_info?.zip_code;

  const handleViewInvoice = async (invoiceId: string) => {
    setLoadingInvoiceId(invoiceId); // Set the loading state for the specific invoice
    try {
      const invoicePdfUrl = await getInvoicePdf(invoiceId);
      const pdfLink = invoicePdfUrl.invoice_pdf.split("/pdf")[0];
      console.log(pdfLink, "_blank");
    } catch (error) {
      console.error("Error fetching invoice PDF:", error);
      // Handle error (e.g., show a notification to the user)
    } finally {
      setLoadingInvoiceId(null); // Reset the loading state
    }
  };

  return (
    <ContentLayout>
      <div className="flex flex-col items-start h-screen pt-5 px-8 w-full space-y-4 overflow-y-scroll">
        <div>
          <h2 className="text-xl dark:text-white font-medium">Billing</h2>
          <p className="text-sm text-zinc-500 dark:text-zinc-400 mt-2 min-w-[550px]">
            Manage your billing information.
          </p>

          {/* Billing Section */}
          <section>
            <h3 className="text-md dark:text-white font-medium pt-4 mt-4 border-t dark:border-t-zinc-800 w-full">
              Payment Method
            </h3>
            <div className="mt-4">
              {isLoadingCreditCards ? (
                <Skeleton className="h-6 w-full" />
              ) : creditCards &&
                creditCards.data &&
                Array.isArray(creditCards.data) ? (
                <>
                  <InputLabel label="Your card" />
                  <div className="flex items-center w-full justify-between">
                    <div className="flex items-center">
                      <span className="mr-2 dark:text-white">
                        {getBrandIcon(creditCardDetails.brand)}
                      </span>
                      <span className="mr-2 text-sm dark:text-white">
                        {creditCardDetails.brand
                          ? `${creditCardDetails.brand
                              .charAt(0)
                              .toUpperCase()}${creditCardDetails.brand.slice(
                              1
                            )} ending in`
                          : "Card"}
                      </span>
                      <code className="bg-zinc-100 dark:bg-zinc-800 px-1 rounded-md text-sm dark:text-white">
                        {creditCardDetails.lastFourDigits}
                      </code>
                    </div>
                    <div>
                      <Elements stripe={stripePromise}>
                        <UpdateCardDialog />
                      </Elements>
                    </div>
                  </div>
                </>
              ) : (
                <div className="text-sm text-zinc-600 dark:text-zinc-400 mt-4">
                  <InputLabel label="Your card" />
                  <div className="flex items-center w-full justify-between">
                    <p className="text-sm dark:text-white">
                      No credit card on file
                    </p>
                    <div>
                      <Elements stripe={stripePromise}>
                        <UpdateCardDialog />
                      </Elements>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>

          {/* Billing Address Section */}
          <section>
            <div className="mt-4">
              {isLoadingBillingInfo ? (
                <Skeleton className="h-6 w-full" />
              ) : (
                <>
                  <InputLabel label="Billing address" />
                  <div className="flex items-center">
                    <div className="flex items-center w-full justify-between">
                      <div className="flex items-center">
                        {billingInfo.billing_info !== null &&
                        isBillingInfoComplete ? (
                          <p className="text-sm dark:text-white">
                            {billingDetails.address.name} <br />
                            {billingDetails.address.address_line_1} <br />
                            {billingDetails.address.address_line_2 && (
                              <>
                                {billingDetails.address.address_line_2} <br />
                              </>
                            )}
                            {billingDetails.address.city},{" "}
                            {billingDetails.address.state}{" "}
                            {billingDetails.address.zip_code}
                          </p>
                        ) : (
                          <>
                            {!isBillingInfoComplete ? (
                              <p className="text-sm items-center flex dark:text-white">
                                <TbAlertTriangleFilled className="inline-block flex-shrink-0 text-zinc-500 mr-1.5" />
                                Please update your billing address
                              </p>
                            ) : (
                              <p className="text-sm items-center flex dark:text-white">
                                <TbAlertTriangleFilled className="inline-block flex-shrink-0 text-zinc-500 mr-1.5" />
                                No billing address provided
                              </p>
                            )}
                          </>
                        )}
                      </div>
                      <div>
                        <EditBillingInformationDialog
                          billingInfo={billingDetails.address}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>

          {/* Invoices Section */}
          {billingDetails && billingDetails.invoices.length > 0 && (
            <section className="space-y-4">
              <h3 className="text-md dark:text-white font-medium pt-4 mt-4 border-t dark:border-t-zinc-800 w-full mb-4">
                Invoices
              </h3>
              {isLoadingInvoices ? (
                <Skeleton className="h-6 w-full" />
              ) : (
                <ul>
                  {shouldFetchUpcomingInvoice && upcomingInvoice && (
                    <>
                      <InputLabel label="Next invoice" />
                      <p className="text-sm dark:text-white mb-4">
                        {dayjs(upcomingInvoice.date * 1000).format(
                          "MMM DD, YYYY"
                        )}{" "}
                        for{" "}
                        {(upcomingInvoice.amountDue / 100).toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: upcomingInvoice.currency,
                          }
                        )}
                      </p>
                    </>
                  )}
                  <InputLabel label="Recent invoices" />
                  {billingDetails.invoices.map((invoice, index) => (
                    <li
                      key={index}
                      className={cn(
                        "flex justify-between items-center pb-2.5 border-b dark:border-b-zinc-800 pt-2"
                      )}
                    >
                      <div className="flex items-center">
                        <div className="h-full">
                          {invoice.paid ? (
                            <Tag color="emerald" className="w-fit">
                              Paid
                            </Tag>
                          ) : (
                            <Tag color="rose">Unpaid</Tag>
                          )}
                        </div>
                        <div className="flex flex-col ml-4">
                          <p className="text-xs dark:text-white ">
                            {dayjs(invoice.date * 1000).format("MMMM D, YYYY")}
                          </p>
                          {invoice.amountDue >= 0 && (
                            <div>
                              <p className=" text-xs dark:text-white text-zinc-400">
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: invoice.currency,
                                }).format(invoice.amountDue / 100)}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <Button
                        variant="outlineBlur"
                        className="w-fit"
                        size="xs"
                        isLoading={loadingInvoiceId === invoice.id} // Use the loading state here
                        onClick={() => handleViewInvoice(invoice.id)}
                      >
                        View invoice
                      </Button>
                    </li>
                  ))}
                </ul>
              )}
            </section>
          )}
        </div>
      </div>
    </ContentLayout>
  );
};
