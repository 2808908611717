import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/Elements";
import { DeleteEntity } from "@/features/documents/components/DeleteDocument";
import { MoreHorizontal } from "lucide-react";
import React from "react";

export const ActionsCell = {
  Cell: ({ row, table, type }) => {
    const fraseDocument = row.original;
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [hasOpenDialog, setHasOpenDialog] = React.useState(false);
    const dropdownTriggerRef = React.useRef(null);

    const handleDialogItemSelect = () => {
      dropdownTriggerRef.current?.focus();
    };

    const handleDialogItemOpenChange = (open) => {
      setHasOpenDialog(open);
      if (!open) {
        setDropdownOpen(false);
      }

      // Delay restoring pointer events
      setTimeout(() => {
        document.body.style.pointerEvents = "auto";
      }, 2000);
    };

    return (
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="buttonIcon"
            className="hover:bg-zinc-200"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent hidden={hasOpenDialog}>
          <DropdownMenuGroup
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <DropdownMenuItem
              className="DropdownMenuItem"
              onClick={() => {
                console.log(`/app/${type}s/${fraseDocument.hash}`, "_blank");
              }}
            >
              Open in new tab
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator className="DropdownMenuSeparator" />
          <DropdownMenuGroup
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <DeleteEntity
              entityIds={[fraseDocument.id]}
              entityTitles={[
                fraseDocument.text && fraseDocument.text[0]?.title,
              ]}
              entityType={type}
              onClose={handleDialogItemOpenChange}
              onSelect={handleDialogItemSelect}
              onOpenChange={handleDialogItemOpenChange}
            />
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  },
};
