import { Button } from "@/components/Elements";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/Elements/Chart/Chart";
import { Skeleton } from "@/components/Elements/Skeleton";
import { ContentLayout } from "@/components/Layout";
import { useGenerateApiToken } from "@/features/api/api/generateApiToken";
import { useGetApiUsage } from "@/features/api/api/getApiUsage";
import { useGetUserOrg } from "@/features/auth/api/getUserOrg";
import { useAuth } from "@/lib/auth";
import { useNotificationStore } from "@/stores/notifications";
import { useMemo } from "react";
import { TbCopy } from "react-icons/tb";
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";

export const Api = () => {
  const { user } = useAuth();
  const { addNotification } = useNotificationStore();
  const { mutateAsync: generateApiToken, isLoading: isGeneratingToken } =
    useGenerateApiToken({
      orgId: String(user?.orgId || ""),
    });

  const {
    data: userOrg,
    isLoading: isUserOrgLoading,
    refetch: refetchUserOrg,
  } = useGetUserOrg({
    userHash: user?.hash || "",
  });
  const apiToken = userOrg?.auth_token;

  const { data: apiUsage, isLoading: isApiUsageLoading } = useGetApiUsage({
    orgId: String(user?.orgId || ""),
  });

  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiToken || "");
    addNotification({
      type: "success",
      title: "API key copied to clipboard",
    });
  };

  const monthlyCallsData = useMemo(() => {
    const months = Array.from({ length: 12 }, (_, i) => {
      const date = new Date();
      date.setMonth(date.getMonth() - i);
      return date.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
    }).reverse();

    return months.map((month) => {
      const entries = apiUsage?.["12_months"]?.[month.replace(" ", "_")] || [];
      const totalUrlCount = entries.reduce(
        (sum, entry) => sum + (entry.url_count || 0),
        0
      );
      return {
        month,
        totalUrlCount,
      };
    });
  }, [apiUsage]);

  const chartConfig = {
    totalUrlCount: {
      label: "Processed URL Count",
      color: "hsl(var(--chart-1))",
    },
  } satisfies ChartConfig;

  return (
    <ContentLayout>
      <div className="flex flex-col items-start h-screen pt-5 pl-8 w-full space-y-4 overflow-y-scroll pb-20">
        <div className="w-[550px]">
          <h2 className="text-xl dark:text-white font-medium">API</h2>
          <p className="text-sm text-zinc-500 dark:text-zinc-400 mt-2 min-w-[550px]">
            Manage your API settings and access information.
          </p>
          <h3 className="text-md dark:text-white font-medium pt-4 mt-4 border-t dark:border-t-zinc-800 w-full">
            Frase API
          </h3>
          <div className="space-y-4 w-full my-4">
            <p className="text-sm text-zinc-500 dark:text-zinc-400">
              The Frase API allows you to build apps, workflows, and
              integrations on top of Frase. For more details on API plans,
              contact{" "}
              <a href="mailto:team@frase.io" className="text-blue-500">
                team@frase.io
              </a>
              .
            </p>
            <p>
              <strong>
                <Button
                  onClick={() =>
                    console.log("https://docs.frase.io/", "_blank")
                  }
                  variant="textNode"
                >
                  API Documentation →
                </Button>
              </strong>
            </p>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="w-full border-t dark:border-t-zinc-800 py-4">
              <h3 className="text-md dark:text-white font-medium">
                Personal API keys
              </h3>
              <div className="space-y-4 w-full my-4">
                <p className="text-sm text-zinc-500 dark:text-zinc-400">
                  You can create personal API keys to access Frase's API for
                  building custom integrations or applications.
                </p>

                <div className="flex items-center w-full">
                  {isUserOrgLoading ? (
                    <Skeleton className="h-8 w-full" />
                  ) : apiToken ? (
                    <div className="flex items-center space-x-2 bg-zinc-100 dark:bg-zinc-800 pl-2 pr-0.5 py-0.5 ring-inset rounded-md ring-1 ring-zinc-200 dark:ring-zinc-700 w-full justify-between">
                      <span className="text-sm text-zinc-900 dark:text-zinc-400">
                        {apiToken}
                      </span>
                      <Button
                        variant="buttonIcon"
                        buttonIcon={<TbCopy />}
                        onClick={copyToClipboard}
                      ></Button>
                    </div>
                  ) : (
                    <Button
                      variant="primaryBlur"
                      size="xs"
                      isLoading={isGeneratingToken}
                      disabled={isGeneratingToken}
                      onClick={() => {
                        generateApiToken().then(() => refetchUserOrg());
                      }}
                    >
                      Create new API key
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {apiToken && (
            <div className="w-full border-t dark:border-t-zinc-800 py-4">
              <h3 className="text-md dark:text-white font-medium">API Usage</h3>
              <div className="space-y-4 h-full w-full">
                <div className="flex items-center gap-2 space-y-0 py-5 sm:flex-row">
                  <div className="grid flex-1 gap-1 text-center sm:text-left">
                    <h4 className="text-sm dark:text-white font-medium">
                      Monthly Processed URLs
                    </h4>
                    <p className="text-sm text-zinc-500 dark:text-zinc-400">
                      Track your monthly processed URLs for the past year. Paid
                      Frase plans allow processing up to 500 URLs per month for
                      free using the API.
                    </p>
                    <p className="text-sm text-zinc-500 dark:text-zinc-400">
                      To process additional URLs, contact{" "}
                      <a href="mailto:team@frase.io" className="text-blue-500">
                        team@frase.io
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div>
                  {isApiUsageLoading ? (
                    <Skeleton className="h-[250px] w-full" />
                  ) : (
                    <ChartContainer config={chartConfig}>
                      <AreaChart data={monthlyCallsData}>
                        <defs>
                          <linearGradient
                            id="fillDesktop"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="var(--color-desktop)"
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor="var(--color-desktop)"
                              stopOpacity={0.1}
                            />
                          </linearGradient>
                          <linearGradient
                            id="fillMobile"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="var(--color-mobile)"
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor="var(--color-mobile)"
                              stopOpacity={0.1}
                            />
                          </linearGradient>
                        </defs>
                        <CartesianGrid vertical={false} />
                        <XAxis
                          dataKey="month"
                          tickLine={false}
                          axisLine={false}
                          tickMargin={8}
                          minTickGap={32}
                          tickFormatter={(value) => {
                            const date = new Date(value);
                            return date.toLocaleDateString("en-US", {
                              month: "short",
                            });
                          }}
                        />
                        <YAxis
                          tickLine={false}
                          axisLine={false}
                          tickMargin={8}
                          tickCount={3}
                        />
                        <ChartTooltip
                          cursor={false}
                          content={
                            <ChartTooltipContent
                              color="#059669"
                              labelFormatter={(value) => {
                                return new Date(value).toLocaleDateString(
                                  "en-US",
                                  {
                                    month: "short",
                                  }
                                );
                              }}
                              indicator="dot"
                            />
                          }
                        />
                        <Area
                          dataKey="totalUrlCount"
                          type={"monotone"}
                          fill="#059669"
                          stroke="#059669"
                          stackId="a"
                        />
                        <ChartLegend content={<ChartLegendContent />} />
                      </AreaChart>
                    </ChartContainer>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </ContentLayout>
  );
};
