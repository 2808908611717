import { ContentLayout } from "@/components/Layout";
import { BsWordpress } from "react-icons/bs";
import { SiGooglechrome, SiGoogledocs } from "react-icons/si";
import { Button, Card } from "../../../components/Elements";

export const Integrations = () => {
  return (
    <ContentLayout>
      <div className="flex flex-col items-start h-screen pt-5 px-8 w-full space-y-4 overflow-y-scroll">
        <div>
          <h2 className="text-xl dark:text-white font-medium ">Integrations</h2>
          <p className="text-sm text-zinc-500 dark:text-zinc-400 mt-2 pb-4 mb-4 min-w-[550px] border-b dark:border-b-zinc-800">
            Enhance your content workflow with these powerful integrations.
          </p>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            {/* Google Docs Add-on Integration */}
            <Card className="flex flex-col">
              <div className="flex items-center space-x-2">
                <div className="flex items-center justify-center w-10 h-10 m-2 rounded-md bg-white/90 dark:bg-zinc-900/50 shadow-sm ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5 focus:outline-none">
                  <SiGoogledocs className="text-2xl text-blue-500 dark:text-blue-400" />
                </div>

                <h3 className="text-base dark:text-white font-medium">
                  Google Docs Add-on
                </h3>
              </div>
              <p className="text-sm text-zinc-500 dark:text-zinc-400 px-2 mt-2 pb-4">
                Use Frase AI to optimize and improve content in Docs.
              </p>
              <Button
                variant="primaryBlur"
                className="w-fit mx-2 mb-2"
                onClick={() =>
                  console.log(
                    "https://workspace.google.com/marketplace/app/frase/265423059553",
                    "_blank"
                  )
                }
              >
                Install Add-On
              </Button>
            </Card>

            {/* Wordpress Plugin Integration */}
            <Card className="flex flex-col">
              <div className="flex items-center space-x-2">
                <div className="flex items-center justify-center w-10 h-10 m-2 rounded-md bg-white/90 dark:bg-zinc-900/50 shadow-sm ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5 focus:outline-none">
                  <BsWordpress className="text-2xl text-zinc-700 dark:text-zinc-300" />
                </div>

                <h3 className="text-base dark:text-white font-medium">
                  WordPress Plugin
                </h3>
              </div>
              <p className="text-sm text-zinc-500 dark:text-zinc-400 px-2 mt-2 pb-4">
                Seamlessly optimize and enhance content directly within
                WordPress.
              </p>
              <Button
                variant="primaryBlur"
                className="w-fit mx-2 mb-2"
                onClick={() =>
                  console.log("https://wordpress.org/plugins/frase/", "_blank")
                }
              >
                Install Plugin
              </Button>
            </Card>

            {/* Chrome Extension Integration */}
            <Card className="flex flex-col">
              <div className="flex items-center space-x-2 flex-shrink-0">
                <div className="flex items-center justify-center w-10 h-10 m-2 rounded-md bg-white/90 dark:bg-zinc-900/50 shadow-sm ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5 focus:outline-none">
                  <SiGooglechrome className="text-2xl" />
                </div>
                <h3 className="text-base dark:text-white font-medium">
                  Chrome Extension
                </h3>
              </div>
              <p className="text-sm text-zinc-500 dark:text-zinc-400 px-2 mt-2 pb-4">
                Enhance your writing with Frase AI tools across the web.
              </p>
              <Button
                variant="primaryBlur"
                className="w-fit mx-2 mb-2 mt-auto"
                onClick={() =>
                  console.log(
                    "https://chromewebstore.google.com/detail/frase-your-ai-writing-sup/aobfdmffblkbjcbdppaackahbjbcpgaf",
                    "_blank"
                  )
                }
              >
                Install Extension
              </Button>
            </Card>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
