import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  NewDocumentButton,
  Spinner,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Elements/Table";
import {
  cleanUrl,
  sortByVariable,
  useSeoAnalyticsStore,
} from "@/features/seo-analytics/store";
import { PageData } from "@/features/seo-analytics/types";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { ChevronDown, ChevronUp, Plus } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TbExternalLink, TbHelpCircleFilled } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { StatusBadge } from "../../../StatusBadge";

const PAGE_SIZE = 10;

const PagesTable: React.FC = () => {
  const {
    allPages,
    statusList,
    statusFilter,
    setStatusFilter,
    sort,
    sortType,
    setSort,
    setSortType,
    kwFilter,
    loading,
    populateDocMap,
    getQueriesForSite,
    siteUrl,
    activeQuery,
    activeGeo,
  } = useSeoAnalyticsStore();

  const [currentPage, setCurrentPage] = useState(1);
  const [urlQueryMap, setUrlQueryMap] = useState<
    Record<string, { value: string }[]>
  >({});
  const [isDocumentCreating, setIsDocumentCreating] = useState<null | string>(
    null
  );

  useEffect(() => {
    if (!loading) setCurrentPage(1);
  }, [loading]);

  const navigate = useNavigate();

  const filteredPages = useMemo(() => {
    setCurrentPage(1);

    const filterAndSort = (list: PageData[]) => {
      const filteredList = list.filter((item) => {
        const statusMatch =
          statusFilter === "all" || item.status === statusFilter;
        const kwMatch =
          !kwFilter || item.url.toLowerCase().includes(kwFilter.toLowerCase());
        return statusMatch && kwMatch;
      });

      return sortByVariable(filteredList, false, sort, sortType);
    };

    if (!allPages) return [];
    return filterAndSort(allPages);
  }, [allPages, statusFilter, kwFilter, sort, sortType]);

  const sortList = (field: keyof PageData) => {
    if (sort === field) {
      setSortType(sortType === "ascending" ? "descending" : "ascending");
    } else {
      setSort(field as keyof PageData);
      setSortType("descending");
    }
  };

  const handleCreateDocument = useCallback(
    async (e: React.MouseEvent<HTMLElement>, url: string) => {
      e.stopPropagation();
      const target = e.target as HTMLElement;
      const parentSpan = target.closest("span");
      setIsDocumentCreating(url);
      const queries = await getQueriesForSite(
        siteUrl || "",
        url,
        activeQuery,
        activeGeo?.gscCode || ""
      );
      if (queries !== "error") {
        const { list = [] } = queries[0];
        const query = list
          .sort((a, b) => (b.impressions || 0) - (a.impressions || 0))
          .slice(0, 3)
          .map(({ query }) => ({ value: query }));
        setUrlQueryMap((prev) => ({ ...prev, [url]: query }));
      }

      if (parentSpan) {
        parentSpan.click();
      } else {
        console.warn("Parent span not found");
      }
      setIsDocumentCreating(null);
    },
    [siteUrl, activeQuery, activeGeo]
  );

  const kFormatter = (num: number) => {
    return Math.abs(num) > 999
      ? Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(1)) + "k"
      : Math.sign(num) * Math.abs(num);
  };

  const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getPageCount = (total: number) => Math.ceil(total / PAGE_SIZE);

  const paginatedPages =
    filteredPages?.slice(
      (currentPage - 1) * PAGE_SIZE,
      currentPage * PAGE_SIZE
    ) || [];

  if (loading) {
    return (
      <div className="relative flex items-center w-full h-[50%] justify-center">
        <LogoSpinner variant="md" loadingText="Loading pages..." />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex h-fit justify-between mx-4 py-2 space-x-4">
        <div className="flex items-center">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="2xs"
                className="inline-flex items-center justify-between w-auto"
                endIcon={<ChevronDown />}
              >
                <div className="flex items-center">
                  <span>
                    {statusFilter.replace(/\b\w/g, (char) =>
                      char.toUpperCase()
                    )}{" "}
                    {statusFilter === "all" ? " Pages" : ""}
                  </span>
                </div>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              {statusList.map((status) => (
                <DropdownMenuItem
                  key={status.name}
                  onSelect={() => {
                    setStatusFilter(status.name);
                  }}
                >
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <span
                        className="w-3 h-3 rounded-full mr-2"
                        style={{ backgroundColor: status.color }}
                      />
                      <span className="text-zinc-900 dark:text-zinc-100">
                        {status.name.replace(/\b\w/g, (char) =>
                          char.toUpperCase()
                        )}
                      </span>
                    </div>
                    {status.desc && (
                      <TooltipProvider
                        delayDuration={500}
                        skipDelayDuration={0}
                      >
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div className="text-zinc-400 hover:text-zinc-600 dark:text-zinc-500 dark:hover:text-zinc-300">
                              <TbHelpCircleFilled size={16} />
                            </div>
                          </TooltipTrigger>
                          <TooltipPortal>
                            <TooltipContent className="z-[999999999]">
                              <span>{status.desc}</span>
                            </TooltipContent>
                          </TooltipPortal>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                  </div>
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          <span className="ml-4 text-xs text-zinc-700 dark:text-zinc-300">
            {kFormatter(filteredPages?.length || 0)} pages
          </span>
        </div>
        {filteredPages && filteredPages.length > PAGE_SIZE && (
          <div className="flex items-center">
            <Button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              variant="outline"
              size="2xs"
              className="text-zinc-700 dark:text-zinc-300"
            >
              Previous
            </Button>
            <span className="mx-4 text-zinc-700 dark:text-zinc-300 text-xs">
              {currentPage} / {getPageCount(filteredPages.length)}
            </span>
            <Button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage * PAGE_SIZE >= filteredPages.length}
              variant="outline"
              size="2xs"
              className="text-zinc-700 dark:text-zinc-300"
            >
              Next
            </Button>
          </div>
        )}
      </div>
      {paginatedPages.length > 0 && (
        <div className="w-full">
          <Table className="w-full">
            <TableHeader className="bg-white dark:bg-zinc-900 shadow-sm">
              <TableRow>
                <TableHead
                  className="w-[30%]"
                  style={{
                    boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                  }}
                >
                  Page URL
                </TableHead>
                <TableHead
                  className="w-[10%]"
                  style={{
                    boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                  }}
                >
                  <Button
                    variant="textNode"
                    endIcon={
                      sort === "clicks" && sortType === "descending" ? (
                        <ChevronDown />
                      ) : (
                        <ChevronUp />
                      )
                    }
                    onClick={() => sortList("clicks")}
                  >
                    Clicks
                  </Button>
                </TableHead>
                <TableHead
                  className="w-[10%]"
                  style={{
                    boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                  }}
                >
                  <Button
                    variant="textNode"
                    endIcon={
                      sort === "impressions" && sortType === "descending" ? (
                        <ChevronDown />
                      ) : (
                        <ChevronUp />
                      )
                    }
                    onClick={() => sortList("impressions")}
                  >
                    Impressions
                  </Button>
                </TableHead>
                <TableHead
                  className="w-[10%]"
                  style={{
                    boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                  }}
                >
                  <Button
                    variant="textNode"
                    endIcon={
                      sort === "ctr" && sortType === "descending" ? (
                        <ChevronDown />
                      ) : (
                        <ChevronUp />
                      )
                    }
                    onClick={() => sortList("ctr")}
                  >
                    CTR
                  </Button>
                </TableHead>
                <TableHead
                  className="w-[10%]"
                  style={{
                    boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                  }}
                >
                  <Button
                    variant="textNode"
                    endIcon={
                      sort === "position" && sortType === "descending" ? (
                        <ChevronDown />
                      ) : (
                        <ChevronUp />
                      )
                    }
                    onClick={() => sortList("position")}
                  >
                    Position
                  </Button>
                </TableHead>
                <TableHead
                  className="w-[10%]"
                  style={{
                    boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                  }}
                >
                  <Button
                    variant="textNode"
                    className="whitespace-nowrap"
                    endIcon={
                      sort === "clicks_growth" && sortType === "descending" ? (
                        <ChevronDown />
                      ) : (
                        <ChevronUp />
                      )
                    }
                    onClick={() => sortList("clicks_growth")}
                  >
                    Clicks Growth
                  </Button>
                </TableHead>
                <TableHead
                  className="w-[10%]"
                  style={{
                    boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                  }}
                >
                  Status
                </TableHead>
                <TableHead
                  className="w-[7%] text-center whitespace-nowrap"
                  style={{
                    boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                  }}
                >
                  Frase Doc
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {paginatedPages.map((p, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className="w-[30%] items-center space-x-2 dark:text-white">
                      <Button
                        variant="buttonIcon"
                        buttonIcon={<TbExternalLink />}
                        onClick={(e) => {
                          e.stopPropagation();
                          console.log(p.url, "_blank");
                        }}
                      ></Button>
                      <span className=" text-zinc-900 dark:text-white">
                        {cleanUrl(p.url)}
                      </span>
                    </TableCell>
                    <TableCell className="w-[10%]">
                      {p.clicks ? numberWithCommas(p.clicks) : "-"}
                    </TableCell>
                    <TableCell className="w-[10%]">
                      {p.impressions ? numberWithCommas(p.impressions) : "-"}
                    </TableCell>
                    <TableCell className="w-[10%]">
                      {p.ctr ? p.ctr.toFixed(2) : "-"}
                    </TableCell>
                    <TableCell className="w-[10%]">
                      {p.position ? p.position.toFixed(2) : "-"}
                    </TableCell>
                    <TableCell className="w-[10%]">
                      {p.clicks_growth && p.clicks_growth > 1
                        ? `${p.clicks_growth}x`
                        : "-"}
                    </TableCell>
                    <TableCell className="w-[10%]">
                      <StatusBadge
                        color={p.color}
                        className="whitespace-nowrap"
                      >
                        {p.status}
                      </StatusBadge>
                    </TableCell>
                    <TableCell className="w-[7%] text-center">
                      {p.fraseDoc ? (
                        <Button
                          variant="buttonIcon"
                          buttonIcon={<TbExternalLink color="#059669" />}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/app/documents/${p.fraseDoc.hash}`);
                          }}
                          tooltipContent="View document"
                          disabled={Boolean(isDocumentCreating)}
                        ></Button>
                      ) : (
                        <NewDocumentButton
                          defaultValues={{
                            importUrl: p.url,
                            queries: urlQueryMap[p.url],
                          }}
                          onDocumentCreated={populateDocMap}
                          skipWorkflowSelection
                          trigger={
                            <Button
                              variant="buttonIcon"
                              buttonIcon={
                                isDocumentCreating === p.url ? (
                                  <Spinner size="sm" />
                                ) : (
                                  <Plus />
                                )
                              }
                              tooltipContent="Create a new document"
                              onClick={(e) => handleCreateDocument(e, p.url)}
                              disabled={Boolean(isDocumentCreating)}
                            ></Button>
                          }
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}
      {filteredPages && filteredPages.length === 0 && (
        <div className="flex flex-col items-center justify-center w-full h-[50%] text-center">
          <p className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">
            No pages found
          </p>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {kwFilter
              ? "Try adjusting your search criteria or filters."
              : "There are no pages matching the current filters."}
          </p>
        </div>
      )}
    </div>
  );
};

export default PagesTable;
