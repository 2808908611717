import { Button, Input } from "@/components/Elements";
import { ContentLayout } from "@/components/Layout";
import { useEffect, useState } from "react";
import { TbCheck, TbX } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { InputLabel } from "../../../components/Elements";
import { countryOptions } from "../../../components/Elements/Dialog/newDocumentOptions";
import { CountrySelect } from "../../ai/components/CountrySelect";
import { LanguageSelect } from "../../ai/components/LanguageSelect";
import { useUser } from "../../auth";
import { useUpdateUserInformation } from "../../users/api/updateUser";
import { DeleteAccountButton } from "../components/DeleteAccountDialog";

export const Account = () => {
  const navigate = useNavigate();
  const { data: user, isLoading: isLoadingUser } = useUser();
  const userGeography = JSON.parse(user?.geography || "{}");
  const [selectedCountry, setSelectedCountry] = useState(
    userGeography?.code || "us"
  );
  const [primaryDomain, setPrimaryDomain] = useState(user?.domain || "");
  const [fullName, setFullName] = useState(user?.fullName || "");
  const [selectedLanguage, setSelectedLanguage] = useState(
    userGeography?.lang || "en"
  );

  const { mutate: updateUser, isLoading: isUpdatingUser } =
    useUpdateUserInformation();

  useEffect(() => {
    if (user) {
      setSelectedCountry(userGeography.code || "us");
      setPrimaryDomain(user.domain || "");
      setFullName(user.fullName || "");
      setSelectedLanguage(userGeography.lang || "en");
    }
  }, [user]);

  const handleCountryChange = (countryCode) => {
    setSelectedCountry(countryCode);
  };

  const handleSaveUserInfo = () => {
    updateUser({
      name: fullName,
      domain: primaryDomain,
      geography: JSON.stringify({
        display_code: countryOptions.find(
          (country) => country.value === selectedCountry
        )?.label,
        code: selectedCountry,
        lang: selectedLanguage,
      }),
    });
  };

  return (
    <ContentLayout>
      <div className="flex flex-col items-start h-screen pt-5 px-8 w-full space-y-4 overflow-y-scroll">
        <div>
          <h2 className="text-xl dark:text-white font-medium">Account</h2>
          <p className="text-sm text-zinc-500 dark:text-zinc-400 mt-2 min-w-[550px]">
            Manage your account information, including your name, email address,
            and password.
          </p>
          <h3 className="text-md dark:text-white font-medium pt-4 mt-4 border-t dark:border-t-zinc-800 w-full">
            General
          </h3>
          <div className="space-y-4 w-full mt-4">
            <Input
              label="Full Name"
              value={fullName}
              placeholder="Enter your full name"
              onChange={(value) => setFullName(value)}
              isLoading={isLoadingUser}
            />
            <div className="flex flex-col">
              <Input
                className="disabled:opacity-100"
                type="email"
                label="Email Address"
                defaultValue={user?.username}
                disabled={user?.verified}
                placeholder="Enter your email address"
                isLoading={isLoadingUser}
              />

              <div className="text-sm text-zinc-500 mt-2 flex justify-between w-full">
                <div className="flex text-xs">
                  {user?.verified ? (
                    <div>
                      <TbCheck className="inline-block flex-shrink-0 text-emerald-600 mb-0.5" />
                      <span className="ml-2">Email verified</span>
                    </div>
                  ) : (
                    <div>
                      <TbX className="inline-block flex-shrink-0 text-red-500 mb-0.5" />
                      <span className="ml-2">Email not verified</span>
                    </div>
                  )}
                </div>
                <div className="text-xs">
                  <span>Need to change your email?</span>
                  <Button
                    variant="link"
                    className="ml-2 hover:underline"
                    textClassName="text-xs"
                    onClick={() =>
                      console.log("https://support.frase.io/", "_blank")
                    }
                  >
                    Contact support
                  </Button>
                </div>
              </div>
            </div>
            <Input
              label="Primary Domain"
              value={primaryDomain}
              placeholder="Enter your primary domain"
              onChange={(value) => setPrimaryDomain(value)}
              isLoading={isLoadingUser}
            />
            <div>
              <InputLabel label="Primary Geography" />
              <CountrySelect
                selectedCountry={selectedCountry}
                setSelectedCountry={handleCountryChange}
                triggerClassName={undefined}
                className={undefined}
                isLoading={isLoadingUser}
              />
            </div>
            <div>
              <InputLabel label="Primary Language" />
              <LanguageSelect
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                triggerClassName={undefined}
                className={undefined}
                isLoading={isLoadingUser}
              />
            </div>
            <Button
              type="button"
              variant="primaryBlur"
              className="mt-2 w-fit"
              onClick={handleSaveUserInfo}
              isLoading={isUpdatingUser}
              disabled={isLoadingUser || isUpdatingUser}
              size="xs"
            >
              Update
            </Button>
            <div className="flex flex-col">
              <div className="w-full border-t dark:border-t-zinc-800 py-4">
                <InputLabel label="Password" />
                <Button
                  variant="outlineBlur"
                  className="w-fit "
                  onClick={() => {
                    navigate("/resetpassword");
                  }}
                  size="xs"
                  disabled={isLoadingUser}
                >
                  Change password
                </Button>
              </div>
              <div className="w-full border-t dark:border-t-zinc-800 py-4">
                <InputLabel label="Account" />
                <DeleteAccountButton
                  className="w-fit"
                  userEmail={user?.username}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
