import { Button, Spinner } from "@/components/Elements";
import { ContentLayout } from "@/components/Layout";
import storage from "@/utils/storage";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { getExtensionToken } from "../api/getExtensionToken";

const slides = [
  {
    image: "https://cdn.frase.io/sites/fe/images/extension/step1.png",
    caption: "Pin Frase to your browser for quick access to critical features.",
  },
  {
    image: "https://cdn.frase.io/sites/fe/images/extension/step2.gif",
    caption:
      "Highlight text to reveal the Frase Assist menu. Open the menu and select your editing action.",
  },
];

const Slideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="relative w-full max-w-2xl mx-auto mt-8">
      <div className="relative h-[400px] overflow-hidden rounded-lg">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-500 ${
              index === currentSlide ? "opacity-100" : "opacity-0"
            }`}
          >
            <img
              src={slide.image}
              alt={`Slide ${index + 1}`}
              className="object-cover w-full h-full"
            />
          </div>
        ))}
        <Button
          variant="buttonIcon"
          className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-50 dark:bg-gray-700 dark:bg-opacity-75 text-white dark:text-gray-200 rounded-full p-2"
          onClick={prevSlide}
        >
          <ChevronLeftIcon className="w-6 h-6" />
        </Button>
        <Button
          variant="buttonIcon"
          className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-50 dark:bg-gray-700 dark:bg-opacity-75 text-white dark:text-gray-200 rounded-full p-2"
          onClick={nextSlide}
        >
          <ChevronRightIcon className="w-6 h-6" />
        </Button>
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {slides.map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 rounded-full border ${
                index === currentSlide
                  ? "bg-blue-500 border-blue-700 dark:bg-blue-400 dark:border-blue-300"
                  : "bg-gray-300 border-gray-400 dark:bg-gray-600 dark:border-gray-500"
              }`}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </div>
      </div>
      <div className="mt-4 bg-black bg-opacity-50 dark:bg-gray-800 dark:bg-opacity-75 text-white p-4 rounded-lg">
        <p className="text-center">{slides[currentSlide].caption}</p>
      </div>
    </div>
  );
};

export const ActivateExtension = () => {
  const [isActivated, setIsActivated] = useState(false);
  const [isChromeRuntime, setIsChromeRuntime] = useState(true);

  useEffect(() => {
    const activateExtension = async () => {
      try {
        if (chrome?.runtime) {
          const chromeExtensionId: string = import.meta.env
            .VITE_CHROME_EXTENSION_ID!;
          const extensionToken = await getExtensionToken();
          const token = storage.getToken();
          const refreshToken = storage.getRefreshToken();

          chrome.runtime.sendMessage(
            chromeExtensionId,
            {
              message: "userAuth",
              tokens: { token, refreshToken, extensionToken },
            },
            (response) => {
              if (response && response.status) {
                setIsActivated(true);
              }
            }
          );
        } else {
          setIsChromeRuntime(false);
        }
      } catch (error) {
        setIsChromeRuntime(false);
      }
    };

    activateExtension();
  }, []);

  if (!isChromeRuntime) {
    return (
      <ContentLayout title="Chrome Extension Required">
        <div className="flex flex-col justify-center items-center min-h-screen max-w-4xl mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold text-center mb-6 text-gray-900 dark:text-white">
            Google Chrome Required
          </h1>
          <p className="text-xl text-center mb-8 text-gray-700 dark:text-gray-300">
            The Frase Chrome Extension requires Google Chrome browser to
            function properly.
          </p>
          <Button
            variant="primaryBlur"
            size="lg"
            onClick={() =>
              console.log(
                "https://chrome.google.com/webstore/detail/frase-extension-id",
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            Get Frase Extension for Chrome
          </Button>
        </div>
      </ContentLayout>
    );
  }

  if (!isActivated) {
    return (
      <ContentLayout title="Welcome to Frase Chrome Extension">
        <div className="flex flex-col justify-center items-center min-h-screen max-w-4xl mx-auto px-4 py-8">
          <Spinner size="md" />
        </div>
      </ContentLayout>
    );
  }

  return (
    <ContentLayout title="Welcome to Frase Chrome Extension">
      <div className="flex flex-col justify-center items-center min-h-screen max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-center mb-6 text-gray-900 dark:text-white">
          Thank you for installing the Frase Chrome Extension!
        </h1>
        <p className="text-xl text-center mb-8 text-gray-700 dark:text-gray-300">
          Get ready to supercharge your content creation process.
        </p>
        <Slideshow />
        <div className="mt-12 text-center">
          <p className="text-lg mb-4 text-gray-700 dark:text-gray-300">
            You're all set! The extension has been successfully authenticated.
          </p>
          <p className="text-lg text-gray-700 dark:text-gray-300">
            You can now close this tab and start using the Frase Chrome
            Extension.
          </p>
        </div>
      </div>
    </ContentLayout>
  );
};
